<template>
  <div class="zq-brand-logo" @click="logoNavigate">
    <img
        class="mr-1 zq-header-brand__main"
        size="custom-size"
        :height="35"
        :width="35"
        src="@/assets/icons/logo.svg"
        alt="logo"
    />
    <div>
      <img :src="require('@/assets/icons/Logo-v4.svg')" alt="logo" class="zq-header-brand">
<!--      <span class="zq-header-brand__main">Competition</span><span class="zq-header-brand">Labs</span>-->
    </div>
  </div>
</template>

<style lang="scss">
@import "../../assets/scss/mixins";

$br-point: 640px;

.zq-brand-logo {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: #1d1d1b;
  cursor: pointer;
}

.zq-header-brand__main {
  display: none;
}

.zq-header-brand{
  font-size: 1.4rem;
  font-weight: 600;
  color: #1d1d1b;
  height: 42px;
  width: 256px;
  margin-left: 6px;
}

//.zq-header-brand__main, .zq-header-brand{
//font-family: "QuicksandPro", serif;
//}

@include media-breakpoint-down($br-point) {
   .zq-header-brand {
    display: none;
  }
}
@include media-breakpoint-down($br-point) {
  .zq-header-brand__main {
    display: block;
  }
}
</style>

<script>
export default {
  methods: {
    logoNavigate() {
      this.$router.push('/')
    }
  }
}
</script>